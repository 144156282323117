export const capitalizeFirstLetter = (string) => {
  return string?.length > 0
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : string;
};

export const camelCaseToWords = (str) => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result?.length > 0
    ? result.charAt(0).toUpperCase() + result.slice(1)
    : result;
};

export const allFieldsRemoveAny = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== "Any") {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

export const removeNullUndefinedFields = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (Array.isArray(obj[key])) {
      if (obj[key].length > 0) {
        acc[key] = obj[key];
      }
    } else if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

export const noWali = (currentUser) => {
  if (currentUser.gender === "female") {
    if (!currentUser.waliDetails) {
      return true;
    }
    if (!JSON.parse(currentUser.waliDetails).email) {
      return true;
    }

    return false;
  } else {
    return false;
  }
};

export const noDob = (currentUser) => {
  if (currentUser.dob === "0000-00-00" || !currentUser.dob) {
    return true;
  }
  return false;
};

export const isEmail = (email) => {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
};

export function validatePassword(password) {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(password);
}
